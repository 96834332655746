<template lang="pug">
  .claim-details.font-weight-medium(v-if="claim")
    .d-flex.align-items-center
      i.icon-claims-1.text-gray-600.mr-2
      b-link.text-gray-600.mr-2(to="/user/claims") Claims
      i.icon-chevron-right.text-gray-600.mr-2
      span.text-blackpearl.font-weight-semibold.mr-1.text-truncate
        | {{ isActiveClaim ? 'Ongoing Claims' : 'Previous Claims' }}
      span.text-blackpearl.text-truncate - {{ claim.dependent? claim.dependent.name: claim.user.name}}

    .row.mt-4
      .col-md-4.mb-3
        claim-journey(:claim="claim",
        :claimFiles="uploadedFiles",
        ref="claimJourney",
        @refetchFiles="$apollo.queries.claimFiles.refetch()",
        @refetchClaim="$apollo.queries.claim.refetch()")

        r-m-card.rm-card-container(
          v-if="relationshipManager && getFeatureFlags['RM_CARD']"
          :name="relationshipManager.name",
          variant = "vertical"
          :photoFile="relationshipManager.photoFile")

      .col-md-8
        b-card.details-card(no-body)
          b-tabs(
            data-cy="claims-navbar",
            pills,
            content-class="border-top p-4",
            nav-wrapper-class="claim-nav-wrapper font-weight-semibold",
            active-nav-item-class="claim-active-tab",
            v-model="selectedSection")
            b-tab(title="Details", data-cy="claim-details")
              .d-flex.justify-content-between.align-items-center.mt-3.flex-wrap
                .d-flex.align-items-center.pt-2
                  i.icon-claims-1.mr-2(style="margin-top:-5px;").text-gray-500
                  h4.hb4.m-0.text-gray-900  Claim Details
                n-button(
                  v-if="isAdmin",
                  variant="outline-secondary",
                  buttonText="Edit Details",
                  imageIcon="edit",
                  :to="`/admin/claims/${claim.id}/edit`")
                n-button(
                  data-cy="claims-edit-details",
                  v-else,
                  variant="outline-secondary",
                  buttonText="Edit Details",
                  imageIcon="edit",
                  @click="openEditModal")
              hr
              .row
                .col-md-6.pt-3
                  span.text-gray-700 Patient Name
                  .d-flex.align-items-center.mt-2
                    n-avatar(
                      :size="2.125",
                      :name="claim.dependent?claim.dependent.name:claim.user.name",
                    )
                    .d-flex.flex-column.ml-3
                      span.text-gray-900.font-md {{claim.dependent?claim.dependent.name:claim.user.name}}
                      span.text-gray-700.font-sm.text-capitalize {{ claim.dependent?claim.dependent.relation:'self'}}

                .col-md-6.pt-3
                  span.text-gray-700 Claim Amount
                  .d-flex.align-items-center.mt-2
                    .d-flex.border.p-2
                      i.icon-rupee.text-gray-900.font-lg.pr-2
                      span.text-gray-700.font-lg.border-left.pl-2
                        | {{ getFormattedNumber(claim.meta.claimAmount) || '-' }}

                .col-md-6.pt-3
                  span.text-gray-700 Policy
                  .d-flex.flex-column
                    .d-flex.align-items-center.mt-2
                      i.icon-hospital.text-red-500.mr-1
                      span.text-gray-900.font-md.text-truncate(
                        v-b-tooltip.hover,
                        :title="getPolicyType(claim.policyType)")
                        | {{ getPolicyType(claim.policyType) }}
                    span.text-gray-700.font-sm {{claim.policy.insurer.name}}
                .col-md-6.pt-3(v-if="claim.meta.approvedAmount")
                  span.text-gray-700 Approved Amount
                  .d-flex.align-items-center.mt-2
                    .d-flex.border.p-2.bg-teal-100
                      i.icon-rupee.text-gray-900.font-lg.pr-2
                      span.text-gray-700.font-lg.border-left.pl-2 {{ getFormattedNumber(claim.meta.approvedAmount) }}
                .col-md-6.pt-3
                  span.text-gray-700 Claim Type
                  .d-block
                    span.d-inline-flex.align-items-center.mt-2.badge-pill.py-1.font-sm(v-if="claim.type", :class="{\
                      'bg-purple-100': claim.type==='reimbursement',\
                      'text-purple-600': claim.type==='reimbursement',\
                      'bg-malibu-100': claim.type==='cashless',\
                      'text-malibu-800': claim.type==='cashless',\
                    }")
                      i.mr-1(:class="{\
                        'icon-money': claim.type==='reimbursement',\
                        'icon-institution': claim.type==='cashless'\
                      }")
                      span.font-weight-semibold.text-capitalize {{ claim.type }}
                    span.py-1.font-sm(v-else) -

                .col-md-6.pt-3
                  span.text-gray-700 Hospital
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ hospitalName }}
                      span.text-gray-700.font-sm {{ claim.meta.city }}

              hr.mt-4
              .row
                .col-md-6.pt-3
                  span.text-gray-700 Procedure Type
                  .d-flex.flex-column
                    .d-flex.align-items-center.mt-2
                      i.mr-1(:class="{ \
                        'icon-bed': claim.meta.category!=='daycare', \
                        'icon-hr24-store': claim.meta.category==='daycare', \
                        'text-blue-400': claim.meta.category!=='daycare',\
                        'text-red-400': claim.meta.category==='daycare', \
                      }")
                      span.text-gray-900.font-md
                        | {{claim.meta.category==='daycare'?'Daycare':'Hospitalization'}}
                    span.text-gray-700.font-sm.font-weight-normal
                      | Patient was hopitalized for {{claim.meta.category==='daycare'?'a Single':'more than a'}} day
                .col-md-6.pt-3
                  span.text-gray-700 Hospitalization Period
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column(
                      v-if="getFormattedDate(claim.details.doa) && getFormattedDate(claim.details.dod)")
                      span.text-gray-900.font-md
                        | {{getDateDifference(claim.details.doa, claim.details.dod)}} Days
                      span.text-gray-700.font-sm
                        | {{getFormattedDate(claim.details.doa)}} -> {{ getFormattedDate(claim.details.dod) }}
                    .d-flex.flex-column(v-else)
                      span.text-gray-900.font-md -
              hr.mt-4
              .row
                .col-md-6.pt-3
                  span.text-gray-700 Diagnosis
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ claim.details.diagnosis || '-' }}
                .col-md-6.pt-3(v-if="claim.details.doctor")
                  span.text-gray-700 Treating Doctor
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ claim.details.doctor || '-' }}
                .col-md-6.pt-3
                  span.text-gray-700 Additional Details
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ claim.details.details || '-' }}
              hr.mt-4
              .row.mb-4
                .col-md-6.pt-3
                  span.text-gray-700.font-sm Claim Initiated on
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ getFormattedDate(claim['Created At']) || '-' }}
                .col-md-6.pt-3
                  span.text-gray-700.font-sm Claim Status Last Updated
                  .d-flex.align-items-center.mt-2
                    .d-flex.flex-column
                      span.text-gray-900.font-md {{ getFormattedDate(claim['Last Update']) || '-' }}
                .col-md-6.pt-3.mt-3
                  span.text-gray-700.font-sm Claim Updates are shared with
                  .d-flex.align-items-center.mt-2
                    .d-block.flex-column
                      span.d-inline-flex.align-items-center.badge-pill.bg-gray-200.p-2
                        i.icon-mail.text-gray-600.mr-1.font-sm
                        span.text-gray-900.font-sm
                          | {{ claim.user.email}}
                      br
                      span.d-inline-flex.align-items-center.badge-pill.bg-gray-200.p-2.mt-2(v-if="claim.meta.contact")
                        i.icon-mobile.text-gray-600.mr-1.font-sm
                        span.text-gray-900.font-sm
                          | {{claim.meta.contact}}          
            b-tab(title="Documents", lazy, data-cy="claim-documents-tab")
              template(v-if="showClaimLetters")
                claim-letters(:letters="uploadedFiles" :claimOwner="claim?.claimOwner?.name" :exotelNumber="claim?.claimOwnerExotelNumber")
              template
                .border.shadow-2.rounded.px-0.overflow-hidden.p-0.mt-5
                  .d-flex.align-items-center.p-3(:class="documentState.isCompleted?'bg-blue-500':'bg-mustard-500'")
                    .d-flex.flex-column
                      span.font-sm(style="opacity:0.75",:class="documentState.isCompleted?'text-white':'text-gray-900'") DOCUMENTS UPLOAD
                      span.font-weight-medium.font-sm(:class="documentState.isCompleted?'text-white':'text-gray-900'") {{ documentState.heading }}
                  .bg-white.p-4
                    p.text-gray-900.font-weight-medium.font-sm {{ documentState.content }}
                    .down-content.mt-4
                      n-button( variant="outline-secondary", :buttonText="documentState.actionText", rightImageIcon="chevron-right" :to="{name:'upload-documents', params:{id: claim.id}}", data-cy="claim-documents-btn")
            b-tab(title="Queries", data-cy="claim-query-tab")
              .d-flex.justify-content-between.align-items-center.mt-3(data-cy="claim-query")
                .d-flex.align-items-center.pt-2
                  i.icon-swap-horizontal.mr-2(style="margin-top:-5px;").text-gray-500
                  h4.hb4.m-0.text-gray-900 Queries
              .border-top.mt-4
              ul.m-0.p-0.timeline.px-3(v-if="claim.queries.length")
                li.row.position-relative.border-bottom.mt-3(v-for="query, index in claim.queries")
                  .col-md-3.time.pt-3
                    span.text-gray-700.d-block.font-sm Recieved on
                    span.text-gray-900.font-md {{ getFormattedDate(query.raisedAt)}}
                    span.text-gray-700.d-block.font-sm {{ getFormattedTime(query.createdAt) }}
                    span.text-gray-700.d-block.font-sm.pt-4 Query Status
                    
                    query-status-pill.mt-2(:status="query.status")
                    span.text-gray-700.d-block.font-sm.mt-2(v-if="query.claimantRespondedAt")
                      | {{getFormattedDate(query.claimantRespondedAt)}}, {{getFormattedTime(query.claimantRespondedAt)}}

                  .col-md-9.content.pt-3
                      query-details(:query="query")
                      .d-flex.align-items-center.pt-3.mb-4
                        n-button.mr-2(
                          v-if="query.status === QueryStatus.OPEN",
                          buttonText="Need Help?",
                          variant="light",
                          size="sm",
                          @click="showSalesIqChat")
                        n-button(
                          v-if="query.status === QueryStatus.OPEN",
                          variant="primary",
                          size="sm",
                          buttonText="Respond",
                          imageIcon="messaging",
                          @click="openQueryModal(index)")
                        n-button(
                          v-else,
                          variant="light",
                          size="sm",
                          buttonText="View Documents",
                          :to="`/user/claims/upload-documents/${claim.id}`")
              .p-5.text-center(v-else)
                h3.h3.text-gray-400 There are no queries.

            b-tab(title="Timeline", data-cy="claim-timeline-tab")
              .d-flex.justify-content-between.align-items-center.mt-3
                .d-flex.align-items-center.pt-2
                  i.icon-timeline.mr-2(style="margin-top:-5px;").text-gray-500
                  h4.hb4.m-0.text-gray-900 Timeline
              claim-timeline(:claim="claim", data-cy="claim-timeline", @goToQueriesTab="goToQueriesTab")
    query-response-modal(
      id="qrm",
      :selectedQuery="selectedQuery",
      :claim="claim",
      :claimFiles="uploadedFiles",
      @fileAdded="$apollo.queries.claimFiles.refetch()"
      @responseSubmitted="$apollo.queries.claim.refetch(), $refs.claimJourney.openQuery = null"
    )
    b-modal#edit-claim-details-modal(centered, title="Edit Details", :hide-footer="true", size="lg", data-cy="edit-claim-details-modal")
      b-form(@submit.prevent="submitClaimUpdate")
        vue-form-generator(
          :schema="updateClaim.schema",
          :model="updateClaim.model",
          :options="formOptions",
          ref="formData")
        b-button(variant="primary", type="submit", data-cy="update-claim-details") Update Claim
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import moment from "moment";
import userDefs from "../definitions";
import adminDefs from "../../admin/definitions";
import { claimDocumentsList } from "../../admin/constants";
import { relationshipManagerFragment } from "../../admin/fragments";
import ClaimJourney from "./components/ClaimJourney.vue";
import QueryResponseModal from "./components/QueryResponseModal.vue";
import QueryStatusPill from "./components/QueryStatusPill.vue";
import QueryDetails from "./components/QueryDetails.vue";
import ClaimLetters from "./components/ClaimLetters.vue";
import validators, { validateIndianMobileNumbersForVfg } from "@/utils/validators";
import utils, { isVfgErrorPresent } from "@/utils";
import NSubmitButton from "@/components/SubmitButton";
import ClaimTimeline from "@/components/ClaimTimeline";
import NButton from "@/components/NovaButton";
import NAvatar from "@/components/Avatar.vue";
import NUploadComponent from "@/components/FileUpload";
import RMCard from "@/components/RMCard.vue";
import { QueryStatus } from "@/common/enums/claims/queryStatus";
import { ClaimStatus } from "@/common/enums/claims/claimStatus";

export default {
  components: {
    NButton,
    NAvatar,
    NSubmitButton,
    NUploadComponent,
    RMCard,
    ClaimTimeline,
    ClaimJourney,
    QueryResponseModal,
    QueryStatusPill,
    QueryDetails,
    ClaimLetters,
  },
  data() {
    return {
      fields: ["Created At", "Policy Type", "Policy Name", "Last Update", "Status", "Actions", "History"],
      show: false,
      userId: null,
      claim: null,
      totalCount: null,
      selectedQuery: null,
      items: [],
      uploadedFiles: [],
      claimFiles: [],
      selectedSection: 0,
      hospitalName: null,
      updateClaim: {
        id: null,
        schema: ((_schema) => {
          const schema = utils.deepClone(_schema);
          schema.fields.find((f) => f.model === "status").visible = false;
          const meta = schema.fields.find((f) => f.model === "meta");
          meta.schema.fields = meta.schema.fields.filter((f) => f.model !== "queries" && f.model !== "files");
          const patientField = meta.schema.fields.find((f) => f.label === "Patient Name");
          if (patientField) {
            patientField.values = [
              ...this.$store.state.user.dependents,
              { name: this.$store.state.user.name, id: null },
            ];
            patientField.disabled = true;
          }
          meta.schema.fields.find((f) => f.model === "expectedApprovalDate").visible = false;
          meta.schema.fields.find((f) => f.model === "approvedAmount").visible = false;
          meta.schema.fields.find((f) => f.model === "emergency").visible = false;
          meta.schema.fields.find((f) => f.model === "contact").validator = [validateIndianMobileNumbersForVfg];
          meta.schema.fields.find((f) => f.model === "dod").validator = [
            validators.validateDateOfAdmissionDischargeForVfg,
          ];
          return meta.schema;
        })(userDefs.claims.schema),
        model: null,
        status: null,
      },
      claimUpdationStatus: null,
      verifyUpload: 0,
      relationshipManager: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      QueryStatus,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "isOrgAdmin", "getFeatureFlags"]),
    isActiveClaim() {
      if (this.claim) {
        const lastUpdate = moment(this.claim.updatedAt);
        const dateDiff = Math.abs(lastUpdate.diff(moment(), "days"));
        if ((dateDiff > 30 && this.claim.status === "REJECTED") || this.claim.status === "CLAIM_SETTLED") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    claimDocuments() {
      return claimDocumentsList;
    },
    isMobile() {
      return utils.isMobileResolution();
    },
    segmentedFiles() {
      const files = {};
      this.uploadedFiles.forEach((file) => {
        if (!file.docType) file.docType = "Other";
        if (!(file.docType in files)) files[file.docType] = [];
        files[file.docType].push(file);
      });
      return files;
    },
    documentState() {
      const totalRequiredDocuments = claimDocumentsList.length;
      const totalUploadedDocuments = Object.keys(this.segmentedFiles).length;
      const isCompleted = totalRequiredDocuments === totalUploadedDocuments;
      let actionText = isCompleted ? "View Documents" : "Upload Documents";
      if (this.getFeatureFlags.MANDATORY_CLAIM_DOCS_FLOW && this.claim.status === "DRAFT") {
        actionText = "Complete Claim Request";
      }

      const mandatoryDocText = this.getFeatureFlags.MANDATORY_CLAIM_DOCS_FLOW
        ? `Without the necessary documents, we won't be able to process your claim. Please upload all required documents to ensure a smooth and speedy settlement!`
        : "You have not submitted all the documents yet. Please upload soon for a quick approval 👋";
      return {
        isCompleted,
        heading: `${totalUploadedDocuments} out of ${totalRequiredDocuments} documents are uploaded`,
        content: isCompleted
          ? "All the documents we require for a quick approval have been already uploaded. Sit back & relax! 👋"
          : mandatoryDocText,
        actionText,
      };
    },
    showClaimLetters() {
      const isClaimCompletedOrRejected = [ClaimStatus.COMPLETED, ClaimStatus.REJECTED].includes(
        this.claim?.meta?.crm?.claimStatus
      );
      this.goToDocsTab();
      return this.getFeatureFlags.ENABLE_CLAIM_LETTERS && isClaimCompletedOrRejected && this.uploadedFiles?.length > 0;
    },
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Claims");
    if (this.$route.hash === "#queries") {
      this.selectedSection = 2;
    } else if (this.$route.hash === "#documents") {
      this.selectedSection = 1;
    }
  },
  methods: {
    goToQueriesTab() {
      this.selectedSection = 2;
    },
    getInitials(name) {
      return utils.getNameInitials(name);
    },
    getFormattedDate(date) {
      return utils.getFormattedDate(date, "D MMMM YYYY");
    },
    getFormattedTime(date) {
      return moment(date).format("h:mm A");
    },
    getFormattedNumber(number) {
      return utils.getFormattedNumber(number);
    },
    getDateDifference(date1, date2) {
      return utils.getDateDifference(date1, date2);
    },
    getPolicyType(policyType) {
      return utils.getPolicyType(policyType);
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    fillModel() {
      this.updateClaim.id = this.claim.id;
      this.updateClaim.status = this.claim.status;
      this.updateClaim.type = this.claim.type;
      this.updateClaim.model = utils.deepClone(this.claim.details);
    },
    openEditModal() {
      this.fillModel();
      this.$bvModal.show("edit-claim-details-modal");
    },
    openQueryModal(idx) {
      this.selectedQuery = this.claim.queries[idx];
      this.$bvModal.show("qrm");
    },
    async submitClaimUpdate(fromFileUpload = false) {
      try {
        this.claimUpdationStatus = "loading";
        if (!fromFileUpload) {
          await this.$refs.formData.validate();
          if (isVfgErrorPresent(this.$refs.formData.errors)) {
            this.$store.commit("addToast", {
              variant: "danger",
              message: this.$refs.formData.errors[0].error,
            });
            this.claimUpdationStatus = null;
            return;
          }
        }
        await this.$apollo.mutate({
          mutation: gql`
            mutation UpsertClaim(
              $meta: JSONObject
              $id: ID
              $userId: ID
              $status: ClaimStatusEnum
              $type: String
              $source: ClaimSourceEnum
            ) {
              upsertClaim(
                input: { meta: $meta, id: $id, userId: $userId, status: $status, type: $type, source: $source }
              ) {
                claim {
                  id
                }
              }
            }
          `,
          variables: {
            status: this.updateClaim.status,
            type: this.updateClaim.type,
            meta: this.updateClaim.model,
            id: this.updateClaim.id,
            userId: this.claim.user.id,
          },
        });
        this.claimUpdationStatus = "success";
        this.$bvModal.hide("edit-claim-details-modal");
        this.$store.commit("addToast", {
          variant: "success",
          message: "Updated Successfully",
        });
        this.$apollo.queries.claim.refetch();
      } catch (err) {
        this.claimUpdationStatus = "failure";
      }
    },
    goToDocsTab() {
      if (this.$route.hash === "claimLetters") {
        this.$nextTick(() => {
          this.selectedSection = 1;
        });
      }
    },
  },
  apollo: {
    userId: {
      query: gql`
        query LoggedInUser {
          me {
            id
          }
        }
      `,
      update(data) {
        return data.me.id;
      },
    },
    relationshipManager: {
      query: gql`
        query LoggedInUser {
          me {
            id
            org {
              id
              relationshipManager {
                ...RelationshipManager
              }
            }
          }
        }
        ${relationshipManagerFragment}
      `,
      update(data) {
        return data?.me?.org?.relationshipManager;
      },
    },
    claim: {
      query: userDefs.claims.singleQuery,
      fetchPolicy: "no-cache",
      variables() {
        return { id: this.$route.params.id };
      },
      update(data) {
        let claim = userDefs.claims.transform(data.node);
        claim = {
          ...claim,
          id: claim.id,
          "Created At": claim.createdAt,
          "Policy Name": claim.policyName,
          "Policy Type": claim.policyType,
          "Last Update": claim.updatedAt,
          status: claim.status,
          details: claim.meta,
          history: claim.history,
          queries: claim.queries ? claim.queries.sort((a, b) => new Date(b.raisedAt) - new Date(a.raisedAt)) : [],
        };
        this.hospitalName = claim.meta.hospital?.name ?? claim.meta.hospital;
        return claim;
      },
    },
    claimFiles: {
      query: adminDefs.claimFiles.getClaimFiles,
      fetchPolicy: "no-cache",
      skip() {
        return !this.claim?.id;
      },
      variables() {
        return { claimId: this.claim.id };
      },
      update(data) {
        this.uploadedFiles = data.claimFiles;
        const patientBenefit = this.claim.user.benefits.find((benefit) => benefit.node.id === this.claim.policyId);
        const eCardUrl = patientBenefit?.meta?.cardUrl;

        /** Possibly move this to the backend later. This is repeating code. */
        if (eCardUrl) {
          this.uploadedFiles.push({
            claimId: this.claim.id,
            docType: "E-Card",
            fileDetails: {
              name: "E-Card.pdf",
              url: eCardUrl,
            },
            meta: { type: "pdf" },
            autoAttached: true,
          });
        }
        this.$root.$emit("claimFilesUpdated", this.uploadedFiles);
      },
    },
  },
};
</script>

<style lang="scss">
.claim-nav-wrapper .nav {
  display: flex;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}
.claim-nav-wrapper .nav-link {
  padding: 0.8rem 0;
  margin: 0 1.5rem;
  white-space: nowrap;
  color: #7886a1;
}

.claim-active-tab {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  color: #2e3749 !important;
  border-bottom: 2px solid #2e3749 !important;
  padding: 1rem 0;
  margin: 0 1.5rem;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.claims .claim-process-timeline {
  margin: 0;
  padding: 0;
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
  list-style: none;
}
.claim-process-timeline li {
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }
  &:before {
    content: "\2713";
    background: $blue-400;
    color: white;
    padding: 0 2px;
    border-radius: 5px;
    position: absolute;
    left: -10px;
    top: 0px;
  }
}

.claims .card-link {
  border-radius: 8px !important;
}

.start-claim-card {
  border-left: 6px solid $malibu-500 !important;
}

.learn-more-card {
  border-left: 6px solid $mustard-500 !important;
}

.gray-cards {
  border-left: 6px solid $gray-300 !important;
}

.details-card {
  background: white;
  border: 1px solid #e5ecfb;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
}

.rm-card-container {
  margin-top: 1em;
}

.timeline {
  li {
    list-style: none;
    .clock-icon {
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      color: $gray-500;
      background-color: white;
    }
  }
}
</style>
